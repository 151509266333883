import { Location, ProjectAmenity, Plan, User, MediaLibraryItem, Unit, Specification } from "./index"
import { formatUnitJson } from "./unit.entity"

export interface Project {
    id?: number;
    name: string;
    latUpdatedOn?: string;
    locationId: number;
    cityId: number;
    districtId: number;
    stateId: number;
    countryId: number;
    neighbourhood: string;
    totalUnits: number;
    totalUnitsSold: number;
    isFeatured: number;
    developer: string;
    developerId: number;
    reraNumber: string;
    sliderDescription: string;
    sliderImages: MediaLibraryItem[];
    priceFrom: number;
    area: string;
    status: string;
    projectCategories: number[];
    statusId: number;
    lat: number;
    lng: number;
    isPriceShown: number;
    summary: string;
    projectNumber: number;
    brochureImage: MediaLibraryItem | string;
    brochureUrl: string | MediaLibraryItem;
    brochureTitle: string;
    specifications: Specification[];
    brochureShortDescription: string;
    amenityTitle: string;
    galleryTitle: string;
    salientFeatureTitle: string;
    colorCode: string;
    planTitle: string;
    planSubtitle: string;
    planPicture: MediaLibraryItem | number;
    plotSize: number;
    builtArea: number;
    isStandAlone: number;
    plotSizeUnit: string;
    builtAreaUnit: string;
    architectureType: number;
    createdBy: string;
    isDeleted: number;
    createdAt: Date;
    updatedAt: Date;
    planPictureId: number;
    gallery: MediaLibraryItem[];
    amenities: number[];
    brochureImageId: number;
    location: Location;
    hotPoints: any[];
    projectSalientFeatures: any[];
    projectFeaturedSalientFeatures: any[];
    projectTestimonials: any[];
    units: Unit[];
    brochureUrlFilename: string;
    yearBuilt?: number;
    startDate: Date;
    endDate: Date;
    isExclusive: number;
    assignedList: ProjectAssignedList[];
    listingDate: Date;
    isPublished: number;
}

export interface ProjectAssignedList {
    id: number;
    propertyId: number;
    assignedBy: number;
    assignedTo: number;
    readWrite: number;
    createdAt: Date,
    updatedAt: Date;
}

export interface ProjectSalientFeature {
    id: number;
    projectId: number;
    isFeatured: number;
    title: string;
    distance: string;
    items: { description: string, distance: string }[];
    lat: number;
    lng: number;
    createdBy: number;
    createdAt: Date;
    updatedAt: Date;
    itemsList: Items[];
}

export interface Items {
    description: any;
    distance:any;
}

export function formatProjectAssignedList(assignedListJson: any): ProjectAssignedList {
    const assignedList: ProjectAssignedList = {
        id: assignedListJson.id,
        propertyId: assignedListJson.property_id,
        assignedBy: assignedListJson.assigned_by,
        assignedTo: assignedListJson.assigned_to,
        readWrite: assignedListJson.read_write,
        createdAt: new Date(assignedListJson.created_at),
        updatedAt: new Date(assignedListJson.updated_at),
    }
    return assignedList;
}

export function formatProjectSalientFeature(projectSalientFeatureJson: any): ProjectSalientFeature {
    const projectSalientFeature: ProjectSalientFeature = {
        id: projectSalientFeatureJson.id,
        projectId: projectSalientFeatureJson.project_id,
        isFeatured: projectSalientFeatureJson.is_featured,
        title: projectSalientFeatureJson.title,
        distance: projectSalientFeatureJson.distance,
        items: JSON.parse(JSON.parse(projectSalientFeatureJson.items)),
        lat: projectSalientFeatureJson.lat,
        lng: projectSalientFeatureJson.lng,
        itemsList: JSON.parse(JSON.parse(projectSalientFeatureJson.items)),
        createdBy: projectSalientFeatureJson.created_by,
        createdAt: new Date(projectSalientFeatureJson.created_at),
        updatedAt: new Date(projectSalientFeatureJson.updated_at)
    }
    return projectSalientFeature;
}

export function formatProjectJson(proj: any) {
    let featuredImages: MediaLibraryItem[] = [];
    let salientFeatures: any[] = [];
    let featuredSalientFeatures: any[] = [];
    if(proj.project_medias && proj.project_medias.length) {
        proj.project_medias.forEach((media: MediaLibraryItem) => {
            if(media.is_featured) {
                featuredImages.push(media);
            }
        });
    }
    if(proj.project_salient_features && proj.project_salient_features.length) {
        proj.project_salient_features.forEach((feature: any) => {
            if(feature.is_featured === 0) {
                salientFeatures.push(feature);
            }
            else {
                featuredSalientFeatures.push(feature);
            }
        })
    }
    let project: Project = {
        id: proj.id,
        name: proj.name || '',
        latUpdatedOn: proj.last_updated_on,
        locationId: proj.location_id || null,
        cityId: proj.location.length? proj.location[0].city_id : null,
        districtId: proj.location.length? proj.location[0].district_id : null,
        stateId: proj.location.length? proj.location[0].state_id : null,
        countryId: proj.location.length? proj.location[0].country_id : null,
        neighbourhood: proj.location.length? proj.location[0].neighbourhood : '',
        totalUnits: proj.total_units || 0,
        totalUnitsSold: proj.units_sold || 0,
        isFeatured: proj.is_featured || 0,
        developer: proj.developer || '',
        developerId: proj.developer_id || null,
        reraNumber: proj.rera_no=='null'? '' : proj.rera_no,
        sliderDescription: proj.short_description || '',
        sliderImages: featuredImages? featuredImages.map((media: any) => {
            media.id = media.media_id;
            return media;
        }) : [],
        priceFrom: proj.price_from || 0,
        area: proj.area || '',
        status: proj.status || '',
        projectCategories: proj.project_categories? proj.project_categories.map((el: any) => +el) : [],
        statusId: proj.status_id || null,
        lat: proj.lat || null,
        lng: proj.lng || null,
        isPriceShown: proj.is_price_shown || 0,
        summary: proj.long_description || '',
        projectNumber: proj.project_number || null,
        brochureImage: proj.brochure_image,
        brochureUrl: proj.brochure_url,
        brochureTitle: proj.brochure_title || '',
        specifications: proj.specification && proj.specification.length? proj.specification.map((spec: any) => JSON.parse(spec)) : [],
        brochureShortDescription: proj.brochure_short_description || '',
        amenityTitle: proj.amenity_title || '',
        galleryTitle: proj.gallery_title || '',
        salientFeatureTitle: proj.salient_feature_title || '',
        colorCode: proj.color_code || '',
        planTitle: proj.plan_title || '',
        planSubtitle: proj.plan_sub_title || '',
        planPicture: proj.plan_picture,
        plotSize: proj.plot_size || 0,
        builtArea: proj.built_area || 0,
        isStandAlone: proj.is_stand_alone || 0,
        plotSizeUnit: proj.plot_size_unit || 'sqmt',
        builtAreaUnit: proj.built_area_unit || 'sqmt',
        architectureType: proj.architecture_type_id || null,
        createdBy: proj.created_by || '',
        isDeleted: proj.is_deleted || 0,
        createdAt: new Date(proj.created_at),
        updatedAt: new Date(proj.updated_at),
        planPictureId: proj.plan_picture_id || null,
        gallery: proj.project_medias.map((media: any) => {
            media.id = media.media_id;
            return media;
        }),
        amenities: proj.project_amenities && proj.project_amenities.length? proj.project_amenities.map((amenity: ProjectAmenity) => amenity.id) : [],
        brochureImageId: proj.brochure_image_id || null,
        location: proj.location[0],
        hotPoints: proj.hot_points || [],
        projectSalientFeatures: salientFeatures.map((feature: any) => formatProjectSalientFeature(feature)),
        projectFeaturedSalientFeatures: featuredSalientFeatures,
        projectTestimonials: proj.project_testimonials || [],
        brochureUrlFilename: '',
        units: [],
        yearBuilt: proj.year_built? proj.year_built : null,
        startDate: proj.start_date? new Date(proj.start_date) : new Date(),
        endDate: proj.end_date? new Date(proj.end_date) : new Date(),
        listingDate: new Date(proj.listing_date),
        isExclusive: proj.is_exclusive || 0,
        assignedList: proj.assigned_list && proj.assigned_list.length? proj.assigned_list.map((item: any) => formatProjectAssignedList(item)) : [],
        isPublished: proj.is_published || 0
    }
    if(proj.properties.length) {
        project.units = proj.properties.map((unit: Unit) =>{
            return formatUnitJson(unit);
        })
    }
    return project;
}