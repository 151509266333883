import { Component } from '@angular/core';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { User } from 'src/app/core/entities';
import { AuthService } from 'src/app/core/services/auth.service';
import { ROLES } from 'src/app/core/helpers/constants';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        NgScrollbarModule,
        MatExpansionModule,
    ]
})
export class SidebarComponent {

    panelOpenState = false;
    roles = ROLES;
    isToggled = false;
    user: User
    userRole: { role: string, name: string, authorityLevel: number }
    constructor(
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private authService: AuthService
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.user = this.authService.currentUserValue;
        this.userRole = this.roles.find(role => role.role === this.user.role)!;
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    getCompanyName(): string {
        if(this.user && this.user.businessName) {
            if(this.user.businessName.length < 30) {
                return this.user.businessName;
            }
            else {
                return this.user.businessName.slice(0, 18) + '...';
            }
        }
        else {
            return 'Hoogewerf';
        }
    }
}