import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';
import { ToggleService } from '../app/components/common/header/toggle.service';
import { filter } from 'rxjs';
import { LocalStorageService } from './core/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title = 'Tagus - Angular 17 Material Design Admin Dashboard Template';

    isToggled = false;
    currentRoute: string;
    isAuthRoute: boolean = true;
    isPreviewRoute: boolean = false;
    constructor(
        public router: Router,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private localStorageService: LocalStorageService
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: any) => {
            this.currentRoute = event.urlAfterRedirects;
            if(!this.currentRoute.includes('/projects/add')) {
                this.localStorageService.removeItem('draftProjectId');
            }
            if(this.currentRoute.includes('/projects/project-preview/')) {
                this.isPreviewRoute = true;
            }
            else {
                this.isPreviewRoute = false;
            }

            if(this.currentRoute.includes('/auth/')) {
                this.isAuthRoute = true;
            }
            else {
                this.isAuthRoute = false;
            }
          });
    }

    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

}